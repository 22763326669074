import React, { useState, useEffect } from 'react';
import { useLocation } from '@reach/router';
import 'styles/global.css';

import Footer from 'components/Footer';
import MobileNav from 'components/MobileNavigation';
import { supportedLanguages } from '../../../constants/supportedLanguages';
import CookieBanner from 'components/CookieBanner';
import { getCookieConsentValue } from 'react-cookie-consent';
import Cookies from 'js-cookie';
import NavigationColor from 'utils/constants/NavigationColor';
import theme, { breakpoints } from 'theme';
import ReactWhatsappButton from 'react-whatsapp-button';
import { Script } from 'gatsby';

interface LayoutProps {
  children: React.ReactNode;
}

const googleAnalyticsId = process.env.GATSBY_GOOGLE_ANALYTICS_ID;
const googleTagManagerId = process.env.GATSBY_GOOGLE_TAG_MANAGER_ID;

const Layout = ({ children }: LayoutProps) => {
  const location = useLocation();
  const [hasConsentValue, setConsentValue] = useState(false);
  const [isLaptop, setIsLaptop] = useState(true);

  const whatsAppPadding: number = isLaptop ? 10 : 12;

  // function loadGooleAnalytics() {
  //   const gtmScript = document.createElement('script');
  //   gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  //     new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  //     j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  //     'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  //   })(window,document,'script','dataLayer','${googleTagManagerId}');`;
  //   document.head.insertBefore(gtmScript, document.head.firstChild);

  //   const gtmNoscript = document.createElement('noscript');
  //   gtmNoscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}"
  //     height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
  //   document.body.insertBefore(gtmNoscript, document.body.firstChild);

  //   const script = document.createElement('script');
  //   script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
  //   script.async = true;
  //   document.head.appendChild(script);

  //   window.dataLayer = window.dataLayer || [];

  //   function gtag() {
  //     window.dataLayer.push(arguments);
  //   }
  //   gtag('js', new Date());
  //   gtag('config', `${googleAnalyticsId}`, {
  //     page_path: window.location.pathname,
  //   } as any);
  // }

  const handleCookieConsent = (value: boolean) => {
    setConsentValue(value);
  };

  const isNotFoundPage = () => {
    switch (location.pathname) {
      case '/en/not-found':
      case '/es/not-found':
      case '/en/something-went-wrong':
      case '/es/something-went-wrong':
        return true;
      default:
        return false;
    }
  };

  const shouldShowFooter = !isNotFoundPage();
  
  const BrevoWebChat = <Script>
      {`
        (function(d, w, c) {
          w.BrevoConversationsID = '64a885a923be191ed932f906';
          w[c] = w[c] || function() {
              (w[c].q = w[c].q || []).push(arguments);
          };
          var s = d.createElement('script');
          s.async = true;
          s.src = 'https://conversations-widget.brevo.com/brevo-conversations.js';
          if (d.head) d.head.appendChild(s);
        })(document, window, 'BrevoConversations');
    `}
  </Script>;

  useEffect(() => {
    const language = window.navigator.language.slice(0, 2);

    if (supportedLanguages.includes(language)) {
      window.localStorage.setItem('language', language);
    } else {
      window.localStorage.setItem('language', 'es');
    }
  }, []);

  useEffect(() => {
    setIsLaptop(window.innerWidth > parseInt(breakpoints.mobile));
    const handleResize = () => {
      setIsLaptop(window.innerWidth > parseInt(breakpoints.mobile));
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  useEffect(() => {
    const consentValue = getCookieConsentValue();
    setConsentValue(consentValue === 'true');
  }, []);

  useEffect(() => {
    if (!hasConsentValue) {
      const googleAnalyticsCookies = Object.keys(Cookies.get()).filter(
        (cookies) => cookies.startsWith('_ga'),
      );
      googleAnalyticsCookies.forEach((cookie) => Cookies.remove(cookie));
    }
  }, [hasConsentValue]);

  // useEffect(() => {
  //   if (hasConsentValue) {
  //     loadGooleAnalytics();
  //   }
  // }, [hasConsentValue]);

 const animated = true;
  return (
    <>
      <MobileNav variant={NavigationColor.LIGHT} iconCSSColorFilter={theme.filters.secondary} />
      {children}
      {shouldShowFooter && <Footer />}

      <ReactWhatsappButton
        animated
        countryCode="1829"
        phoneNumber="6180062"
        style={{
          right:'20px',
          bottom:`${whatsAppPadding}vh`,
          backgroundColor: theme.neutralPalette.neutral9,
          boxShadow: '0 0 0 0 rgba(90, 111, 201, 10)'
        }}
      />

      <CookieBanner onConsent={handleCookieConsent} />
      {hasConsentValue ? BrevoWebChat : null}
      </>
  );
};

export default Layout;