// import flagES from 'images/flag-spain.png';
// import flagPT from 'images/flag-portugal.png';
// import flagEN from 'images/flag-uk.png';

import flagEN from 'images/flags/united_states_flag.png';
import flagES from 'images/flags/dominican_republic_flag.png';

export const getFlagFromLanguage = (lng: string) => {
  switch (lng) {
    case 'en':
      return flagEN;
    case 'es':
      return flagES;
    default:
      break;
  }
};
