import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Box from 'components/Box';

import { rem } from 'polished';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import { breakpoints } from 'theme';
import { GatsbyImageComponent } from 'components/GatsbyImageComponent';

const BackgroundColorWrapper = styled.footer`
  background-color: ${(props) => props.theme.brandPalette.light};
`;

const StyledFooter = styled.div`
  display: flex;
  gap: 126px;

  img {
    height: 80px;  // Sets the height of the logo
    width: auto;   // Preserves aspect ratio
    margin-bottom: 16px;

    @media (max-width: ${breakpoints.tablet}) {
      margin-bottom: 32px;
    }
  }

  ul {
    list-style: none;
  }

  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    gap: 48px;
  }
`;

const CopyrightWrapper = styled.div`
  @media (max-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    order: 3;

    span {
      order: 2;
    }
  }
`;

const Copyright = styled.p`
  color: ${(props) => props.theme.neutralPalette.neutral8};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${rem(12)};
  line-height: ${rem(18)};
  opacity: 0.8;

  @media (max-width: ${breakpoints.tablet}) {
    white-space: normal;
  }
`;

const ContactList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 28px;

  @media (max-width: ${breakpoints.tablet}) {
    order: 1;
    margin-top: 0;
    margin-bottom: 48px;
  }
`;

const ContactTitle = styled.h2`
  color: ${(props) => props.theme.brandPalette.dark};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${rem(12)};
  line-height: ${rem(18)};
`;

const ContactInfo = styled.p`
  color: ${(props) => props.theme.brandPalette.dark};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${rem(12)};
  line-height: ${rem(18)};
`;

const FooterNavigationHeader = styled.h2`
  text-transform: uppercase;
  color: ${(props) => props.theme.neutralPalette.neutral6};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(20)};
  line-height: ${rem(32)};
  margin-bottom: 32px;
`;

const FooterNavigationList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;

  li {
    color: ${(props) => props.theme.brandPalette.dark};
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-size: ${rem(16)};
    line-height: ${rem(24)};

    transition: all 150ms ease-in-out;

    &:hover {
      cursor: pointer;
      color: ${(props) => props.theme.brandPalette.primary};
    }
  }
`;

const FooterNavigationListItem = styled.li`
  color: ${(props) => props.theme.brandPalette.dark};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${rem(16)};
  line-height: ${rem(24)};

  transition: all 150ms ease-in-out;

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.brandPalette.primary};
  }
`;
const ContactListItem = styled.li``;

const ENG_CONTACT_ITEMS = [
  {
    title: 'homepage.footer.email.title',
    value: 'homepage.footer.email.value',
  },
];

const CONTACT_ITEMS = [
  {
    title: 'homepage.footer.phone.title',
    value: 'homepage.footer.phone.value',
  },
  {
    title: 'homepage.footer.email.title',
    value: 'homepage.footer.email.value',
  },
  {
    title: 'homepage.footer.address.title',
    value: 'homepage.footer.address.value',
  },
];

const NAVIGATION_MENU = [
  { key: 'homepage.footer.main_menu.menu_1', href: '/' },
  // { key: 'homepage.footer.main_menu.menu_2', href: '/services' },
  // { key: 'homepage.footer.main_menu.menu_3', href: '/insights' },
  // { key: 'homepage.footer.main_menu.menu_4', href: '/careers' },
  // { key: 'homepage.footer.main_menu.menu_5', href: '/about' },
  { key: 'homepage.footer.main_menu.menu_6', href: '/contact' },
];



const Footer = (): React.JSX.Element  => {
  const { language, t } = useI18next();

  const contacts = language === 'en' ? ENG_CONTACT_ITEMS : CONTACT_ITEMS;


  const [cookiePolicy, setCookiePolicy] = useState(null);
  const [privacyPolicy, setPrivacyPolicy] = useState(null);
  const [termsAndConditionsPolicy, setTermsAndConditionsPolicy] = useState(null);

  useEffect(() => {
      // Async function to dynamically fetch PDFs
      async function fetchPDFs() {
          const cookieModule = await import('assets/pdf/cookie_policy_upgrade.pdf');
          const privacyModule = await import('assets/pdf/privacy_policy_upgrade.pdf');
          const termsModule = await import('assets/pdf/terms_and_conditions_policy_upgrade.pdf');
          
          setCookiePolicy(cookieModule.default);
          setPrivacyPolicy(privacyModule.default);
          setTermsAndConditionsPolicy(termsModule.default);
      }

      fetchPDFs();
  }, []);

  const NAVIGATION_LEGAL = [
    {
      key: 'homepage.footer.more_information.terms_and_conditions',
      url: termsAndConditionsPolicy,
    },
    {
      key: 'homepage.footer.more_information.privacy_policy',
      url: privacyPolicy,
    },
    {
      key: 'homepage.footer.more_information.cookie_policy',
      url: cookiePolicy,
    },
  ];

  return (
    <BackgroundColorWrapper>
      <Box>
        <StyledFooter>
          <CopyrightWrapper>
            <span>
              <Link to="/">
                <GatsbyImageComponent width={'200px'} height={'auto'} imageName={'logo-dark.png'} borderRadius={'50%'} 
                filter={undefined} padding={undefined} shadow={undefined}/>
              </Link>
              <Copyright>{t('homepage.footer.legal_disclaimer')}</Copyright>
            </span>
            <ContactList>
              {contacts.map((item) => (
                <ContactListItem key={item.title}>
                  <ContactTitle>{t(item.title)}</ContactTitle>
                  <ContactInfo>{t(item.value)}</ContactInfo>
                </ContactListItem>
              ))}
            </ContactList>
          </CopyrightWrapper>
          <div>
            <FooterNavigationHeader>
              {t('homepage.footer.main_menu.title')}
            </FooterNavigationHeader>
            <FooterNavigationList>
              {NAVIGATION_MENU.map((item) => {
                return (
                  <FooterNavigationListItem key={item.key}>
                    <Link to={item.href}>{t(item.key)}</Link>
                  </FooterNavigationListItem>
                );
              })}
            </FooterNavigationList>
          </div>
          <div>
            <FooterNavigationHeader>
              {t('homepage.footer.more_information.title')}
            </FooterNavigationHeader>
            <FooterNavigationList>
              {NAVIGATION_LEGAL.map((item) => (
                <FooterNavigationListItem
                  key={item.key}
                  onClick={() => window.open(item?.url ?? '', '_blank')}
                >
                  {t(item.key)}
                </FooterNavigationListItem>
              ))}
            </FooterNavigationList>
          </div>
        </StyledFooter>
      </Box>
    </BackgroundColorWrapper>
  );
};

export default Footer;
