import { rem } from 'polished';
import React from 'react';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import { NAVIGATION_ITEMS } from 'utils/constants';
import LanguageDisplay from './LanguageDisplay';
import { BlackButtonWithBlackHover } from 'components/Button';
import { breakpoints } from 'theme';
import scrollTo from 'gatsby-plugin-smoothscroll';

const Container = styled.div<{key: string, isOpen: boolean, lngMenuIsHalfOpen: boolean}>`
  /* key: ${(props) => props.key}; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  background-color:  ${(props) => props.theme.neutralPalette.white};;
  width: ${({ lngMenuIsHalfOpen }) => (lngMenuIsHalfOpen ? '100%' : '70%')};

  height: 100%;
  position: fixed;
  z-index: 3;
  top: 0px;
  right: 0;
  text-align: right;


  padding-bottom: 70px;

  transition: all 200ms ease-in-out;
  transform: ${({ isOpen }) =>
    isOpen ? 'translateX(0%)' : 'translateX(100%)'};
  transform: ${({ lngMenuIsHalfOpen }) =>
    lngMenuIsHalfOpen && 'translateX(-124px)'};
`;

const ItemList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-right: 24px;
  background-color: transparent;
`;

const Item = styled.li`
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${rem(24)};
  line-height: ${rem(37)};
  color: ${(props) => props.theme.brandPalette.primaryDark};

  .active {
    font-weight: ${(props) => props.theme.fontWeights.semibold};
    font-size: ${rem(28)};
    line-height: ${rem(32)};
  }
`;

interface SidePanelProps {
  key: string,
  isOpen: boolean;
  setLngMenuIsHalfOpen: (bool: boolean) => void;
  lngMenuIsHalfOpen: boolean;
  setSideMenuIsOpen: (bool: boolean) => void;
}

const SidePanelButton = styled(BlackButtonWithBlackHover)`
  position: relative;
  display: flex;
  width: 80%;
  align-self: center;
  justify-content: center;

  @media (max-width: ${breakpoints.tablet}) {
    display: none;
  }

  @media (max-width: ${breakpoints.mobile}) {
    display: flex;
  }
`;

const SidePanelLanguageDisplay = styled.div`
    margin-right: 24px;
`;


const SidePanel = ({
  key,
  isOpen,
  setLngMenuIsHalfOpen,
  lngMenuIsHalfOpen,
  setSideMenuIsOpen,
}: SidePanelProps) => {
  const { t } = useTranslation();

  const handlePanelContainerClick = () => {
    if (lngMenuIsHalfOpen) {
      setLngMenuIsHalfOpen(false);
    }
  };

  return (
    <Container
      key={key}
      onClick={handlePanelContainerClick}
      isOpen={isOpen}
      lngMenuIsHalfOpen={lngMenuIsHalfOpen}
    >
       
      <SidePanelButton onClick={() => scrollTo('#servicios-wop')}>
      {t('button.plans')}
      </SidePanelButton>
      <ItemList>
        {NAVIGATION_ITEMS.map((item) => (
          <Item>
            <Link
              to={item.href}
              activeClassName="active"
              onClick={() => setSideMenuIsOpen(false)}
            >
              {t(item.key)}
            </Link>
          </Item>
        ))}
      </ItemList>

      <SidePanelLanguageDisplay>
       <LanguageDisplay
          lngMenuIsHalfOpen={lngMenuIsHalfOpen}
          setLngMenuIsHalfOpen={setLngMenuIsHalfOpen}
        />
      </SidePanelLanguageDisplay>

    </Container>
  );
};

export default SidePanel;
