import React from 'react';
import 'components/CookieBanner/styles.css';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import theme from 'theme';
import { rem } from 'polished';

interface CookieBannerProps {
  onConsent: (value: boolean) => void;
}

const CookieBanner = ({ onConsent }: CookieBannerProps): React.JSX.Element => {
  const { t } = useTranslation();
  const { brandPalette, neutralPalette, fontWeights } = theme;
  const DAYS_TO_EXPIRE_COOKIES = 30;

  const handleAccept = () => {
    onConsent(true);
  };

  const handleDecline = () => {
    onConsent(false);
  };

  return (
    <CookieConsent
      location="bottom"
      buttonText={t('home.cookies_banner.accept')}
      enableDeclineButton
      declineButtonText={t('home.cookies_banner.reject')}
      onAccept={handleAccept}
      onDecline={handleDecline}
      buttonStyle={{
        backgroundColor: brandPalette.primary,
        color: neutralPalette.white,
        padding: '12px 24px 12px 24px',
        margin: 0,
        marginRight: '22px',
        borderRadius: '8px',
        fontWeight: fontWeights.bold,
      }}
      declineButtonStyle={{
        backgroundColor: neutralPalette.white,
        border: `1px solid ${neutralPalette.neutral3}`,
        color: brandPalette.primary,
        padding: '12px 24px 12px 24px',
        marginLeft: 0,
        marginRight: '22px',
        borderRadius: '8px',
        fontWeight: fontWeights.bold,
      }}
      style={{
        background: brandPalette.light,
        boxShadow: '0px 3px 16px rgba(0, 0, 0, 0.15)',
        color: brandPalette.primary,
        fontWeight: fontWeights.regular,
        fontSize: rem('16px'),
        lineHeight: '24px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      contentStyle={{
        margin: 0,
        color: brandPalette.dark,
      }}
      containerClasses="cookieContainerClass"
      expires={DAYS_TO_EXPIRE_COOKIES}
    >
      {t('home.cookies_banner.body')}
    </CookieConsent>
  );
};

export default CookieBanner;
