import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';


const Container = styled.div<{
  width:string, 
  height:string, 
  filter:string, 
  borderRadius:string
  padding:string
  hasShadow: boolean
}>`
    display: flex;
    box-shadow: ${props => props.hasShadow ? '4px 4px 20px 0px rgba(0, 0, 0, 0.10)' : ''};
    border-radius: ${props => props.borderRadius};
    width: ${props => props.width};
    height: ${props => props.height};
    filter: ${props => props.filter};
    padding: ${props => props.padding};;
`;



export const GatsbyImageComponent = ({ width, height, imageName, filter ,borderRadius, padding, shadow }): React.JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { extension: { in: ["jpeg", "jpg", "png"] } }) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  `);

  const imageNode = data.allFile.nodes.find(node => node.relativePath === imageName);
  const placeHolderNode = data.allFile.nodes.find(node => node.relativePath === 'image_placeholder.png');
  const image = imageNode ? getImage(imageNode) : getImage(placeHolderNode);


  return image != null ?
       <Container 
       width={width ?? 'auto'} 
       height={height ?? 'auto'} 
       filter={filter} 
       borderRadius={borderRadius} 
       padding={padding}
       hasShadow={shadow}> 
          <GatsbyImage image={image} alt="Description of the image"   imgStyle={{ borderRadius: borderRadius }}/>
       </Container> : <></>;
};

