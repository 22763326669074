import React, { useState } from 'react';
import styled from 'styled-components';

import { useI18next } from 'gatsby-plugin-react-i18next';
import { rem } from 'polished';
import { AVAILABLE_LOKALISE_LANGUAGES } from 'utils/constants';
import { getFlagFromLanguage } from 'utils/getCountryFlagFromLanguage';
import arrow from 'images/return-arrow.png';

const Container = styled.div<{ isOpen: boolean; lngMenuIsFullyOpen: boolean }>`
  background-color: ${({ theme, lngMenuIsFullyOpen }) =>
    lngMenuIsFullyOpen ? theme.neutralPalette.white : theme.neutralPalette.neutral2};
  padding: 13px 0 0 13px;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: start;

  width: ${({ lngMenuIsFullyOpen }) => (lngMenuIsFullyOpen ? '100%' : '125px')};
  height: 100%;
  position: fixed;
  z-index: 3;
  right: 0;
  top: 0;

  transition: all 200ms ease-in-out;
  transform: ${({ isOpen }) =>
    isOpen ? 'translateX(0%)' : 'translateX(100%)'};
`;

const CountryList = styled.ul<{ lngMenuIsFullyOpen: boolean }>`
  list-style: none;

  align-self: ${({ lngMenuIsFullyOpen }) =>
    lngMenuIsFullyOpen ? 'center' : 'initial'};
  margin-top: ${({ lngMenuIsFullyOpen }) =>
    lngMenuIsFullyOpen ? '120px' : '170px'};

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding-left: 24px;
  padding-right: 24px;
`;

const PanelNavigation = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 13px;
`;

const CountryItem = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CloseMenuLabel = styled.p`
  font-size: ${rem(18)};
  line-height: ${rem(22)};
  color: ${(props) => props.theme.brandPalette.secondary};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
`;

const FullCountryLng = styled.p<{ active: boolean }>`
  color: ${(props) => props.theme.neutralPalette.black};
  font-size: ${({ active }) => (active ? rem(28) : rem(24))};
  line-height: ${({ active }) => (active ? rem(32) : rem(27))};
  font-weight: ${({ active, theme }) =>
    active ? theme.fontWeights.bold : theme.fontWeights.regular};
`;

const CountryLngAbbreviation = styled.p`
  color: ${(props) => props.theme.brandPalette.black};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
`;

const FullyOpenLngMenuArrow = styled.img`
  filter: ${(props) => props.theme.filters.secondary};
`;

const CountryFlag = styled.img`
  border-radius: 50%;
  width: 36px;
  height: 36px;
`;

interface LanguageMenuProps {
  lngMenuIsHalfOpen: boolean;
  onLanguageSelection: () => void;
}

const LanguageMenu = ({
  lngMenuIsHalfOpen,
  onLanguageSelection,
}: LanguageMenuProps) => {
  const { changeLanguage, language } = useI18next();
  const [lngMenuIsFullyOpen, setLngMenuIsFullyOpen] = useState(false);

  const handleOptionClick = (lng: string) => {
    changeLanguage(lng);
    onLanguageSelection();
    setLngMenuIsFullyOpen(false);
  };

  return (
    <Container
      isOpen={lngMenuIsHalfOpen}
      lngMenuIsFullyOpen={lngMenuIsFullyOpen}
    >
      <PanelNavigation
        onClick={() => setLngMenuIsFullyOpen(!lngMenuIsFullyOpen)}
      >
        <FullyOpenLngMenuArrow src={arrow} alt="Back" />
        {lngMenuIsFullyOpen && <CloseMenuLabel>Back</CloseMenuLabel>}
      </PanelNavigation>
      <CountryList lngMenuIsFullyOpen={lngMenuIsFullyOpen}>
        {AVAILABLE_LOKALISE_LANGUAGES.map((country) => (
          <CountryItem onClick={() => handleOptionClick(country.abbreviation)}>
            <CountryFlag
              src={getFlagFromLanguage(country.abbreviation)}
              alt=""
              aria-hidden="true"
            />
            {lngMenuIsFullyOpen ? (
              <FullCountryLng active={language === country.abbreviation}>
                {country.language}
              </FullCountryLng>
            ) : (
              <CountryLngAbbreviation>
                {country.abbreviation.toUpperCase()}
              </CountryLngAbbreviation>
            )}
          </CountryItem>
        ))}
      </CountryList>
    </Container>
  );
};

export default LanguageMenu;
