import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import menuArrow from 'images/lng-switch-arrow.png';
import { rem } from 'polished';
import { getFlagFromLanguage } from 'utils/getCountryFlagFromLanguage';

const Container = styled.div<{ lngMenuIsHalfOpen: boolean }>`
  display: ${({ lngMenuIsHalfOpen }) =>
    lngMenuIsHalfOpen ? 'none' : 'inline-flex'};
  align-items: center;
  align-self: end;
`;

const CurrentLanguage = styled.p`
  color:  ${(props) => props.theme.brandPalette.black};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${rem(16)};
  line-height: 24px;

  margin-left: 4px;
  margin-right: 16px;
`;

const CountryFlag = styled.img`
  border-radius: 50%;
  width: 36px;
  height: 36px;
`;

const OpenLngMenuArrow = styled.img`
 filter: ${(props) => props.theme.filters.primary};
 `;

interface LanguageDisplayProps {
  setLngMenuIsHalfOpen: (bool: boolean) => void;
  lngMenuIsHalfOpen: boolean;
}

const LanguageDisplay = ({
  setLngMenuIsHalfOpen,
  lngMenuIsHalfOpen,
}: LanguageDisplayProps) => {
  const { i18n } = useTranslation();
  const languageFlag = getFlagFromLanguage(i18n.language);

  return (
    <Container
      onClick={() => setLngMenuIsHalfOpen(true)}
      lngMenuIsHalfOpen={lngMenuIsHalfOpen}
    >
      <CountryFlag src={languageFlag} alt="" aria-hidden="true" />
      <CurrentLanguage>{i18n.language.toUpperCase()}</CurrentLanguage>
      <OpenLngMenuArrow src={menuArrow} alt="Menu" />
    </Container>
  );
};

export default LanguageDisplay;
