import React from 'react';
import styled from 'styled-components';

const SvgIcon = styled.img<{filter:string}>`
  filter: ${(props) => props.filter};
`;

export interface IconAndMessageProps {
    icon: string,
    description: string,
    filterColor: string,
}

const SVGIcon = ({description, filterColor, icon,}: IconAndMessageProps): React.JSX.Element  => {
  return (<SvgIcon filter={filterColor} src={icon} alt={description} />);
};

export default SVGIcon;
