import { rem } from 'polished';
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import dropdownArrow from 'images/dropdown-arrow.png';
import { breakpoints } from 'theme';

const Container = styled.div`
  position: relative;
  display: block;
  background-color: transparent;
`;

const SelectedOption = styled.button`
  width: 88%;
  background-color: transparent;
  color: ${(props) => props.theme.brandPalette.black};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${rem(20)};

  padding-right: 1vw;
  padding-bottom: 6px;

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(18)};
    width: 60%;
    padding-right: 0px;
    padding-left: 0px; 
    padding-right: 5vw;
    padding-bottom: 4px;
  }

  @media (max-width: ${breakpoints.mobile}) {
    font-size: ${rem(16)};
    width: 100%;
    padding-right: 0px;
    padding-left: 0px; 
    padding-bottom: 4px;
  }

`;

const MenuArrow = styled.img<{ sideMenuIsOpen: boolean }>`
  padding: 12px 0px 0px 0px;
  margin-left: 0px;

  @media (max-width: ${breakpoints.tablet}) {
    padding: 8px 30px 0px 0px;
    margin-left: 0px;
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 8px 10px 0px 0px;
    margin-left: 5px;
  }

  src: ${({ sideMenuIsOpen }) =>
    sideMenuIsOpen ? 'none' : `url(${dropdownArrow})`};

  filter: ${(props) => props.theme.filters.white};
`;

const OptionsList = styled.ul<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  gap: 24px;
  min-width: 170px;
  border-radius: 16px;
  list-style: none;
  padding: 24px;
  
  /* background-color: ${(props) => props.theme.brandPalette.secondary}; */
  color: #fff;

  font-weight: ${(props) => props.theme.fontWeights.regular};

  transition: all 300ms ease-in-out;
  background:  ${(props) => props.theme.brandPalette.primary};

  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};

  width: 100%;
  position: absolute;
  z-index: 2;

  .active {
    color: ${(props) => props.theme.neutralPalette.white};
    font-weight: ${(props) => props.theme.fontWeights.bold};
  }
`;

const Option = styled.li<{ isOpen: boolean }>`
  border-radius: 6px;
  color:  ${(props) => props.theme.neutralPalette.neutral2};
  pointer-events: ${({ isOpen }) => (isOpen ? 'initial' : 'none')};
 
  a {
    font-weight: ${(props) => props.theme.fontWeights.regular}; 
  }

  &:hover {
    cursor: pointer;
  }
`;

const ServicesButton = styled.button`
  background-color: transparent;
  display: flex;
  flex-direction: row;
`;


export interface WebsiteToggleOption {
  name: string;
  href: string;
}

interface DropdownProps {
  options: WebsiteToggleOption[];
  defaultOption: WebsiteToggleOption;
  sideMenuIsOpen: boolean;
  dropdownIsOpen: boolean;
  setDropdownIsOpen: (bool: boolean) => void;
}

const WebsiteToggleDropdown = ({
  options,
  defaultOption,
  sideMenuIsOpen,
  dropdownIsOpen,
  setDropdownIsOpen,
}: DropdownProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const handleDropdownClick = () => {
    if (sideMenuIsOpen) {
      return;
    }
    setDropdownIsOpen(!dropdownIsOpen);
  };

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setDropdownIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);


  return (
    <Container ref={ref}>
      <ServicesButton onClick={handleDropdownClick}>
        <SelectedOption>{defaultOption?.name || options[0]?.name}</SelectedOption>
        <MenuArrow src={dropdownArrow} sideMenuIsOpen={sideMenuIsOpen} />
      </ServicesButton>

      <OptionsList isOpen={dropdownIsOpen}>
        {options.map((option) => (
          <Option
            className={option === defaultOption ? 'active' : ''}
            isOpen={dropdownIsOpen}
            key={option.name}
          >
            <a href={option.href}>
              {option.name}
            </a>
          </Option>
        ))}
      </OptionsList>
    </Container>
  );
};

export default WebsiteToggleDropdown;
