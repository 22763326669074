const theme = {
  brandPalette: {
    primaryDark: '#A01E0C',
    primary: '#A01E0C', 
    accent: '#FF3A1D',
    secondary: '#FF8300',
    dark: '#293040',
    light: '#F6F8FB',
    black: '#000000',
    grey: '#EDEDED',
    gray: '#C4C4C4',
    yellow: '#FF8300'
  },
  neutralPalette: {
    white: '#FFFFFF',
    neutral1: '#F8F9FA',
    neutral2: '#F1F3F5',
    neutral3: '#E9ECEF',
    neutral4: '#DEE2E6',
    neutral5: '#CED4DA',
    neutral6: '#ADB5BD',
    neutral7: '#6A7178',
    neutral8: '#4F575E',
    neutral9: '#272B30',
    black: '#000000',
  },
  semanticPalette: {
    info: {
      base: '#FF8300',
      light: '#E6F2F7',
    },
    success: {
      base: '#FF8300',
      light: '#EAF3EB',
    },
    warning: {
      base: '#F7A500',
      light: '#FEF1D9',
    },
    error: {
      base: '#D35549',
      light: '#FBEEED',
    },
  },
  fonts: {
    body: 'Montserrat',
  },
  fontWeights: {
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  filters: {
    secondary: 'invert(66%) sepia(68%) saturate(4303%) hue-rotate(359deg) brightness(100%) contrast(107%)',
    primary: 'invert(30%) sepia(28%) saturate(6693%) hue-rotate(350deg) brightness(103%) contrast(101%)',
    primaryDark: 'invert(23%) sepia(50%) saturate(3646%) hue-rotate(352deg) brightness(93%) contrast(105%)',
    black: 'invert(0%) sepia(0%) saturate(7500%) hue-rotate(327deg) brightness(96%) contrast(104%)',
    white: 'invert(99%) sepia(0%) saturate(0%) hue-rotate(263deg) brightness(114%) contrast(101%)',
    yellowDark: 'filter: invert(66%) sepia(34%) saturate(7312%) hue-rotate(360deg) brightness(102%) contrast(105%)'
  }
};

export const breakpoints = {
  mobile: '480px', 
  tablet: '768px',
  laptop: '1024px',
  desktop: '1280px',
};

export default theme;
