import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from 'theme';

import 'i18n/config';

// const config = {

//   refreshInterval: 15,
//   appName: process.env.GATSBY_UNLEASH_APP_NAME,
//   environment: process.env.GATSBY_UNLEASH_ENVIRONMENT,
// };

const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      {element}
      {/* <FlagProvider config={config}>{element}</FlagProvider> */}
    </ThemeProvider>
  );
};

export default wrapRootElement;
