import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { breakpoints } from 'theme';

import openMenuButton from 'images/mobileNav-open.png';
import closeMenuButton from 'images/mobileNav-close.png';
import scrollTo from 'gatsby-plugin-smoothscroll';
// import WebsiteToggleDropdown from './components/WebsiteToggleDropdown';
import SidePanel from './components/SidePanel';
import LanguageMenu from './components/LanguageMenu';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import NavigationColor from 'utils/constants/NavigationColor';
import SVGIcon from 'components/SVGIcon';
import WebsiteToggleDropdown from './components/WebsiteToggleDropdown';
import { GatsbyImageComponent } from 'components/GatsbyImageComponent';
import Button, { BlackButton, BlackButtonWithBlackHover } from 'components/Button';
import { WEB_TOGGLE_OPTIONS } from 'data/webtoogle';

const Navigation = styled.nav<{isScrolling: boolean }>`
  display: none;
  z-index: 999;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  
  @media (max-width: ${breakpoints.tablet}) {
  
    display:  ${({ isScrolling }) => (isScrolling ? 'flex' : 'none')}; 
    background-color:  ${(props) => props.theme.neutralPalette.white};
    width: 100%;
    position: fixed;
    justify-content: space-around;
    padding: 6px 0px 6px 0px;


    .mobileLogo {
      display: flex;
      height: 52px;  // Sets the height of the logo
      width: 22vw;  // Preserves aspect ratio
    }
  }
 
  @media (max-width: ${breakpoints.mobile}) {
    padding: 1px 0px 1px 0px;
    gap: 14%;

    .mobileLogo {
      display: flex;
      height: 52px;  // Sets the height of the logo
      width: 34vw;   // Preserves aspect ratio
    }
}
`;

const FlexWrapper = styled.div`
  align-items: center;
`;

const ToggleSidePanelButton = styled.button<{ lngMenuIsHalfOpen: boolean }>`
  display: ${({ lngMenuIsHalfOpen }) => (lngMenuIsHalfOpen ? 'none' : 'block')};
  background-color: transparent;
  border: none;
  z-index: 5;
`;

interface Props {
  variant: NavigationColor.LIGHT | NavigationColor.DARK;
  iconCSSColorFilter: string
}

const LogoAndServicesButton = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  column-gap: 1%;
`;

const MobileNavigationButton = styled(BlackButtonWithBlackHover)`
    display: none;
    flex-grow: 1;

    @media (max-width: ${breakpoints.tablet}) {
      display: block;
      padding: 8px 10px;
      min-width: 110px; 
    }

    @media (max-width: ${breakpoints.mobile}) {
      display: none;
      padding: 6px 8px;
    }
`;

const MobileNav = ({ variant, iconCSSColorFilter }: Props): React.JSX.Element  => {
  const [sideMenuIsOpen, setSideMenuIsOpen] = useState(false);
  const [lngMenuIsHalfOpen, setLngMenuIsHalfOpen] = useState(false);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const { t } = useTranslation();
  
  const handleSideMenuButtonClick = () => {
    if (dropdownIsOpen) {
      setDropdownIsOpen(false);
    }

    setSideMenuIsOpen((previousState) => !previousState);
  };

  const handleLanguageSelection = () => {
    setLngMenuIsHalfOpen(false);
    setSideMenuIsOpen(false);
  };


  const handleScroll = () => {
    if (window.pageYOffset >= 130) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.addEventListener('scroll', handleScroll);
  });

  return (
    <Navigation isScrolling={scrolling}>
      <FlexWrapper>
        <LogoAndServicesButton>
          <Link className='mobileLogo' to="/">
          <GatsbyImageComponent
              imageName={variant !== NavigationColor.LIGHT
                ? 'logo-primary.png'
                : "logo-primary-dark.png"}
              width={'200px'}
              height={'auto'}
              borderRadius={''} 
              filter={undefined} 
              padding={undefined} 
              shadow={undefined}         />
          </Link>

          <WebsiteToggleDropdown
            dropdownIsOpen={dropdownIsOpen}
            setDropdownIsOpen={setDropdownIsOpen}
            sideMenuIsOpen={sideMenuIsOpen}
            options={WEB_TOGGLE_OPTIONS} 
            defaultOption={WEB_TOGGLE_OPTIONS[0].name}     
          />

        <MobileNavigationButton
        invertedColors={false}
        onClick={() => scrollTo('#servicios-wop')}
        >
          {t('button.plans')}
       
        </MobileNavigationButton>  
        </LogoAndServicesButton>
      </FlexWrapper>
      <ToggleSidePanelButton
        onClick={handleSideMenuButtonClick}
        lngMenuIsHalfOpen={lngMenuIsHalfOpen}
      >
        <SVGIcon
          icon={sideMenuIsOpen ? closeMenuButton : openMenuButton}
          description="Menu" 
          filterColor={iconCSSColorFilter} 
        />
      </ToggleSidePanelButton>

      <SidePanel
        key={Math.random().toString()}
        isOpen={sideMenuIsOpen}
        setLngMenuIsHalfOpen={setLngMenuIsHalfOpen}
        lngMenuIsHalfOpen={lngMenuIsHalfOpen}
        setSideMenuIsOpen={setSideMenuIsOpen}
      />
      
      <LanguageMenu
        lngMenuIsHalfOpen={lngMenuIsHalfOpen}
        onLanguageSelection={handleLanguageSelection}
      />
    </Navigation>
  );
};

export default MobileNav;

